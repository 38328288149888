import React from 'react'
import '../css/Time.css'

export default function Time(){
    return (
        <>
        <div>
          <h1 style={{color:"#545454", textAlign:"center", marginTop:"1em"}}> Our 45 Years Plan 🚀</h1>
          </div>
        <div className="timeline">
            
          <ul className="timeline-events">
           
            <li className="timeline-event-years-2" style={{marginLeft:"10px"}}> 
              <h2>2021-2026</h2>
              <h3>Applying state of the<br></br> art technologies for AI</h3>
              <h4><a href="https://brieferr.com" 
              rel="noreferrer" 
              style={{color:"#ac9bc0", textDecoration:"underline"}}
              target="_blank">Building NLP </a>, Speech Recognition,<br></br> Computer Vision and<br></br> Crowd-Sourced Data Collection<br></br> modules </h4>
            </li>
            <li className="timeline-event-years-2">
              <h2>2026-2031</h2>
              <h3>Applying state of the<br></br> art technologies for AI</h3>
              <h4>Creating a "model studio" where<br></br> modules can be used together</h4>
            </li>
            <li className="timeline-event-years-2">
              <h2>2031-2036</h2>
              <h3>Applying state of the<br></br> art technologies for AI</h3>
              <h4>Automating data engineering</h4>
            </li>
            <li className="timeline-event-years-2">
              <h2>2036-2041</h2>
              <h3>Generating metamodels<br></br> and agent architecture</h3>
              <h4>Generating a "Meta Model" that<br></br> decides whether the models<br></br> we have are viable</h4>
            </li>
            <li className="timeline-event-years-2">
              <h2>2041-2046</h2>
              <h3>Generating metamodels<br></br> and agent architecture</h3>
              <h4>Investigation of context-free<br></br> learning transfer</h4>
            </li>
            <li className="timeline-event-years-2">
              <h2>2046-2051</h2>
              <h3>Generating metamodels<br></br> and agent architecture</h3>
              <h4>AgentOS</h4>
            </li>
            <li className="timeline-event-years-2">
              <h2>2051-2056</h2>
              <h3>Developing a human-computer<br></br> interface with the created agent</h3>
              <h4>Placing human consciousness<br></br> in vector space, making<br></br> appropriate mappings.</h4>
            </li>
            <li className="timeline-event-years-2">
              <h2>2056-2061</h2>
              <h3>Developing a human-computer<br></br> interface with the created agent</h3>
              <h4>Simulating consciousness on<br></br> the computer and establishing<br></br> direct physical connections.</h4>
            </li>
            <li className="timeline-event-years-2">
              <h2>2061-2066</h2>
              <h3>Developing a human-computer<br></br> interface with the created agent</h3>
              <h4>To carry the consciousness<br></br> and knowledge of civilization<br></br> beyond biological limits.</h4>
            </li>
          </ul>
          <ul className="timelines-years">
            
          </ul>
        </div>
        </>
      );
    }
